.fc-toolbar-title,
.fc-col-header-cell-cushion {
  font-family: Alegreya;
}

.fc-button-primary,
.fc-today-button {
  box-shadow: none !important;
  border: 1px solid #939393 !important;
  color: #1d1d1d !important;
  background-color: transparent !important;
}

.fc-button-primary:hover {
  color: white !important;
  background-color: #1d1d1d !important;
}

.fc-bg-event {
  cursor: pointer;
}

.fc-event {
  cursor: pointer;
}
