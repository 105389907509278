@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-black selection:bg-purple-100;
  }
}

/* Fix scrollbar content jumping issue */
html {
  width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  scroll-behavior: smooth;
}

@layer components {
  .input-label {
    @apply mb-1 block text-left text-xs font-medium uppercase tracking-[0.08em] text-gray-700;
  }

  .input-text {
    @apply block w-full rounded border border-gray-600 px-4 py-2 text-sm placeholder:text-gray-700 focus:outline-none focus:ring-0 focus:ring-offset-0 md:text-base;
  }

  .input-select {
    @apply mt-1 block w-full rounded px-4 py-2 pl-3 pr-10 text-sm placeholder:text-gray-700 focus:border-purple-900 focus:shadow-purple-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:drop-shadow-input md:text-base;
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.no-tailwind * {
  all: revert;
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
